
function chooseNode(item, idx) {
	
	let node = {
			id: `${item.id}-${idx}-node`,
			name: item.prompt,
			nameVisible: true
		},
		slots = [],
		block = {
			type: "tripetto-block-multiple-choice",
			version: "3.2.3",
			choices: [],
			required: (item.required == true)
		};

	if (item.caption !== null && item.caption !== '') {
		block.caption = item.caption;
	}

	if (item.imageURL !== null && item.imageURL !== '') {
		block.imageURL = item.imageURL;
	}

	if (item.description !== null && item.description !== '') {
		node.description = item.description;
	}

	if (item.options !== undefined && item.options.length > 0) {

		let choices = item.options
			.map((c, k) => {
				let opt = {...c};
				if (opt.value === '') {
					opt.value = c.label;
				} else {
					// c.value = Number(c.value);
				}

				return {
					id: `${item.id}-${idx}-choice-${opt.value}`,
					idx: k,
					name: opt.label,
					value: opt.value
				}
			});
		
		for (let c of choices) {

			block.choices.push({
				id: c.id,
				name: c.name,
				value: c.value
			});

			if (item.type == 'choose-n') {
				slots.push({
					id: c.id + '-slot',
					type: "boolean",
					kind: "dynamic",
					reference: c.id,
					sequence: c.idx,
					label: "Choice",
					name: c.name,
					alias: c.value,
					pipeable: {
						group: "Choice",
						label: "Choice",
						template: "name"
					},
					required: item.required
				})
			}

		}
	}
	
	if (item.type == "choose-1") {
		slots.push({
			id: `${item.id}${idx}`.padEnd(64, '0'),
			type: "string",
			kind: "static",
			reference: "choice",
			label: "Choice",
			required: item.required
		})
	}

	if (item.type == 'choose-n') {
		block["multiple"] = true;
	}

	return {
		...node,
		slots,
		block
	};
}

export default chooseNode;