import {neverland as $, render, html, useState, useEffect} from 'neverland';

function assert(condition, message) {
    if (!condition) {
        throw new Error(message || "Assertion failed");
    }
}

const app = new Realm.App({
	id: "prod-sydney-fglkd",
	timeout: 10000
 });

import StyleSheet from './StyleSheet.js';
import Router from './Router.js'

const App = $(function() {

	let pageId = (new URLSearchParams(window.location.search)).get('p');
	
	const [auth, setAuth] = useState(() => {
		return {
			app: app,
			user: null
		}
	});
	
	let [theme, setTheme] = useState(null);
	let [endScreen, setEndScreen] = useState(null);

	let [page, setPage] = useState({
		id: pageId,
		survey: null
	});


	let href = window.location.href;

	async function login() {

		// Create an anonymous credential.
		const credentials = Realm.Credentials.anonymous();

		try {
			
			// Authenticate the user
			const user = await auth.app.logIn(credentials);
			
			// `App.currentUser` updates to match the logged in user
			assert(user.id === auth.app.currentUser.id);

			setAuth(prevAuth => {
				let auth = {...prevAuth};
				auth.user = user;
				return auth;
			})

			return user;
		
		} catch(err) {

			console.warn(err);

		}
	}

	async function loadTheme(user) {

		const themeSpec = await user.functions.fetchTheme(window.location.hostname);

		if (themeSpec !== undefined) {
			setTheme(themeSpec)
		}

		if (page.id == 'EndScreen') {

			let endScreenId = (new URLSearchParams(window.location.search)).get('s');
			if (endScreenId === null) {
				endScreenId = "613bf31aea98708b5101b477";
			}

			const endScreenSpec = await user.functions.fetchEndScreen(endScreenId);
			setEndScreen(endScreenSpec);
		}

	}

	useEffect(() => {
		
		login().then(loadTheme);

	}, [])

	useEffect(() => {

		if (theme !== null) {
			if (theme.hasOwnProperty('favicon')) {
				let fileType = theme.favicon.split('.')[1];

				document.getElementsByTagName("head")[0].insertAdjacentHTML(
					"beforeend",
					`<link rel="icon shortcut" type="image/${fileType}" href="static/img/favicons/${theme.favicon}" />`
				);
			}
		}

	}, [theme])

	return html`
		${StyleSheet(theme)}
		${Router(href, auth, theme, endScreen, page, setPage)}
	`;

});

export default App;