
function textNode(item, idx) {
	
	let node = {
			id: `${item.id}-${idx}-node`,
			name: item.prompt,
			nameVisible: true
		},
		slots = [{
			id: `${item.id}${idx}`.padEnd(64, '0'),
			type: "text",
			kind: "static",
			reference: "value",
			label: (item.type == 'text' ? "Text" : "Multi-line text"),
			required: item.required
		}],
		block = {
			type: (item.type == 'text' ? "tripetto-block-text" : "tripetto-block-textarea"),
			version: "4.2.0"
		};

	if (item.caption !== null && item.caption !== '') {
		block.caption = item.caption;
	}

	if (item.description !== null && item.description !== '') {
		node.description = item.description;
	}

	return {
		...node,
		slots,
		block
	};
}

export default textNode;