
const translations = {
	EN: {
		loginPrompt: 'PLEASE ENTER YOUR 6-DIGIT ACCESS CODE',
		errorNotNumber: {
			strong: 'NOT A NUMBER',
			plain: 'Your access code should be a 6-digit number.'
		},
		errorNotYetOpen: {
			strong: 'NOT SURVEYING YET',
			plain: ''
		},
		errorClosed: {
			strong: 'SURVEY WINDOW CLOSED',
			plain: ''
		},
		errorNoSurvey: {
			strong: 'ACCESS CODE INCORRECT',
			plain: 'Please double-check your code and try again.'
		},
		errorContact: {
			strong: 'SURVEY NOT FOUND',
			plain: "Please contact us and we'll fix it."
		},
	},
	YUE: {
		loginPrompt: '請輸入六位數字代碼。',
		errorNotNumber: {
			strong: '請填上數字',
			plain: '代碼應爲六位數字。'
		},
		errorNotYetOpen: {
			strong: '問卷調查尚未啓用',
			plain: ''
		},
		errorClosed: {
			strong: '問卷調查已關閉',
			plain: ''
		},
		errorNoSurvey: {
			strong: '代碼不正確',
			plain: '請核對代碼再重試。'
		},
		errorContact: {
			strong: 'SURVEY NOT FOUND',
			plain: "Please contact us and we'll fix it."
		},
	},
	CMN: {
		loginPrompt: '请输入六位数字代码。',
		errorNotNumber: {
			strong: '请填上数字',
			plain: '代碼应为六位数字。'
		},
		errorNotYetOpen: {
			strong: '問卷調查尚未啓用',
			plain: ''
		},
		errorClosed: {
			strong: '问卷调查已关闭',
			plain: ''
		},
		errorNoSurvey: {
			strong: '代码不正确',
			plain: '请核对代码再重试。'
		},
		errorContact: {
			strong: 'SURVEY NOT FOUND',
			plain: "Please contact us and we'll fix it."
		},
	}
}

export default translations;