import {neverland as $, html, useState, useEffect} from 'neverland';

import buildForm from '../../../admin/js/tripetto-constructors/buildForm.js';
import { run } from "tripetto-runner-autoscroll";
import { Export } from "tripetto-runner-foundation";


function isInteger(value) {
	return /^\d+$/.test(value);
}

function fixType(value) {
	if (value == "true") {
		return true;
	} else if (value == "false") {
		return false;
	} else if (isInteger(value)) {
		return Number(value);
	} else {
		return value;
	}
}

function groupByNode(slots) {

	slots = slots.map(slot => {
		slot.nodeId = slot.node.props().id.split('-').slice(0,2).join('-');
		return slot;
	});

	// Get unique node IDs.
	let nodeIds = slots.map(d => d.nodeId).filter((item, i, ar) => ar.indexOf(item) === i);

	let nodes = [];

	for (let id of nodeIds) {
		let matchingSlots = slots.filter(slot => slot.nodeId == id);
		nodes.push({
			id: id,
			slots: matchingSlots,
			type: matchingSlots[0].type
		})
	}

	return nodes;
}

function getRandomSubarray(arr, size) {

    var shuffled = arr.slice(0), i = arr.length, temp, index;

    while (i--) {
        index = Math.floor((i + 1) * Math.random());
        temp = shuffled[index];
        shuffled[index] = shuffled[i];
        shuffled[i] = temp;
    }

    return shuffled.slice(0, Math.min(size, shuffled.length));
}

const Survey = $(function(auth, page, setPage) {

	// Initialise Tripetto.
	useEffect(() => {

		// Keep track of any new Items or ItemSets so that we can set hasResponses = true on submission.

		let newItems = page.survey.Items.filter(d => {
				if (d.hasOwnProperty('hasResponses')) {
					return !d.hasResponses;
				} else {
					return true;
				}
			}).map(d => d._id),
			newItemSets = page.survey.ItemSets.filter(d => {
				if (d.hasOwnProperty('hasResponses')) {
					return !d.hasResponses;
				} else {
					return true;
				}
			}).map(d => d._id);



		let extraItems = [];
		
		// Append rotator items.
		
		let rotatorItems = [];
		if (page.survey.ItemSet.rotators) {
			
			let rotators = page.survey.ItemSet.rotators;
			
			if (page.survey.ItemSet.rotators.length > 1) {
				for (let rotator of rotators) {

					let itemPool = page.survey.RotatorItemSets.filter(d => d.id == rotator.element)[0].elements.map(d => d.element);

					// Append rotator items.
					let thisRotatorItems = getRandomSubarray(itemPool, Number(rotator.n));
					rotatorItems = rotatorItems.concat(thisRotatorItems);
					for (let item of thisRotatorItems) {
						extraItems.push({
							collection: 'Items',
							element: item,
							required: true,
							conditions: [],
							conditionAggregator: ""
						})
					}
				}
			}
		}

		// Append test retest item(s).

		let testRetestItems = [];
		if (page.survey.Survey.includeTestRetest) {

			let itemPool = page.survey.Items.filter(d => d.testRetestEligibility).map(d => d.id);

			if (itemPool.length > 0) {

				// Append test-retest explanation.
				extraItems.push({
					collection: 'Items',
					element: "61cdf469f43e617559227ea1",
					required: true,
					conditions: [],
					conditionAggregator: ""
				})

				// Append test-retest item.
				let testRetestItem = getRandomSubarray(itemPool, 1)[0];
				testRetestItems.push(testRetestItem);
				extraItems.push({
					collection: 'Items',
					element: testRetestItem,
					required: true,
					conditions: [],
					conditionAggregator: ""
				})

			}
		}

		page.survey.ItemSet.elements = page.survey.ItemSet.elements.concat(extraItems);

		// Append RotatorItems to Items so that they are accessible by buildForm.
		
		page.survey.Items = page.survey.Items.concat(page.survey.RotatorItems);

		// Build form.

		let form = buildForm(page.survey);

		run({
			element: document.querySelector('.survey'),
			definition: form,
			styles: {
				direction: "vertical",
				verticalAlignment: "middle",
				hidePast: true,
				hideUpcoming: true,
				showNavigation: "always",
				showProgressbar: false,
				showEnumerators: true,
				autoFocus: true,
				showSeparateSubmit: true,
				showPreviousButton: true,
				showScrollbar: false,
				noBranding: true,
				hideRequiredIndicator: true,
				contract: {
					name: "tripetto-runner-autoscroll",
					version: "5.1.2"
				}
			},
			customCSS: `{ visibility: hidden; } > nav { background-color: #ffffff44 !important; }`,
			onSubmit: async function(instance) {

				let fields = Export.fields(instance).fields
					.filter(f => ![undefined, '', null, NaN].includes(f.value));

				let nodes = groupByNode(fields);

				nodes = nodes.map(node => {
					switch (node.type) {
						
						case 'tripetto-block-dropdown':
							return {
								item: node.id.split('-')[0],
								answer: fixType(node.slots[0].value)
							};
							break;
						
						case 'tripetto-block-multiple-choice':
						
							// if (d.slots[0].node.props().block.multiple) { // <-- Might be more robust
							if (node.slots.length > 1) {
								return {
									item: node.id.split('-')[0],
									answer: node.slots.filter(slot => slot.value).map(slot => fixType(slot.slot.split('-')[3]))
								}
							} else {
								return {
									item: node.id.split('-')[0],
									answer: fixType(node.slots[0].value)
								}
							}
							break;
						
						case 'tripetto-block-text':
						case 'tripetto-block-textarea':
							
							return {
								item: node.id.split('-')[0],
								answer: node.slots[0].value
							}
							break;

						case 'tripetto-block-number':
							
							return {
								item: node.id.split('-')[0],
								answer: node.slots[0].value
							};
							break;
						
						default:
							return {};
							break;
					}
				})				

				// console.log(nodes);

				if (page.hasOwnProperty('accessCode')) {
					nodes = [{
						item: '611718091d9f529a4f4f0d22',
						answer: page.accessCode
					}].concat(nodes);
				}
				
				let response = {
					survey: page.survey.Survey._id.toString(),
					itemSet: page.survey.ItemSet.id,
					timestamp: (new Date()).toString(),
					answers: nodes,
					rotatorItems: rotatorItems,
					testRetestItems: testRetestItems
				}

				let submitted = await auth.user.functions.submitResponse(response, newItems, newItemSets);

				// console.log(submitted);
			}
		});


	}, [])

	// // Add custom CSS.
	useEffect(() => {

		let loadedBaseCSS = false,
			loadedCSSparams = false;

		function loadCSS(iframe) {

			let sheets = document.styleSheets,
				existingLinks = iframe.contentDocument.querySelectorAll("link");

			if (existingLinks.length == 0) {
				// Re-add stylesheets.
				for (let sheet of sheets) {
					
					let { href, title, ownerNode } = sheet;

					if (href !== null & title == "tripetto" & !loadedBaseCSS) {
						// Inject base CSS.

						const link = iframe.contentDocument.createElement("link");
						link.href = href;
						link.rel = "stylesheet";
						iframe.contentDocument.body.appendChild(link);
						loadedBaseCSS = true;
					}

					if (ownerNode.id == 'css-parameters' & !loadedCSSparams) {
						// Inject CSS parameters.

						const style = iframe.contentDocument.createElement("style");
						style.innerHTML = sheet.rules[0].cssText;
						iframe.contentDocument.body.appendChild(style);
						loadedCSSparams = true;
					}
				}
			}

		}

		let iframe = document.querySelector(".survey iframe");

		iframe.addEventListener("load", () => {	
			loadCSS(iframe);
		})

		setTimeout(() => {
			if (!loadedBaseCSS & !loadedCSSparams) {
				loadCSS(iframe);
			}			
		}, 100);

	}, [])


	return html`
		<div class="survey"></div>
	`;

});

export default Survey;