
function numberNode(item, idx) {
	
	let node = {
			id: `${item.id}-${idx}-node`,
			name: item.prompt,
			nameVisible: true
		},
		slots = [{
			id: `${item.id}${idx}`.padEnd(64, '0'),
			type: "numeric",
			kind: "static",
			reference: "number",
			label: "Number",
			precision: (item.precision ? Number(item.precision) : 0),
			required: item.required
		}],
		block = {
			type: "tripetto-block-number",
			version: "4.1.1"
		};

	if (item.caption !== null && item.caption !== '') {
		block.caption = item.caption;
	}

	if (item.description !== null && item.description !== '') {
		node.description = item.description;
	}

	return {
		...node,
		slots,
		block
	};
}

export default numberNode;