import {neverland as $, html, useState, useEffect} from 'neverland';

import Select from './Select.js';
import translations from './translations.js';

import JGIcodes from './JGIcodes.js';

const Login = $(function(auth, setPage, theme) {

	let [language, setLanguage] = useState('EN');

	let [text, setText] = useState(translations['EN']);

	useEffect(() => {
		setText(translations[language]);
	}, [language])

	useEffect(() => {
		let lang = (new URLSearchParams(window.location.search)).get('language');
		if (lang !== null) {
			setLanguage(lang);
		}
	}, [])

	function getCodeBoxElement(index) {
		return document.getElementById('ac' + index);
	}

	function handleKeyUp(index) {
		return (event) => {
			const eventCode = event.which || event.keyCode;
			if (getCodeBoxElement(index).value.length === 1) {
				if (index !== 6) {
					getCodeBoxElement(index+ 1).focus();
				} else {
					getCodeBoxElement(index).blur();
					document.getElementById('err-noSurvey').style.height = "0";
					document.getElementById('err-notNumber').style.height = "0";
					document.getElementById('loading-animation').style.display = "block";
					loadSurvey(auth);
				}
			}
			if (eventCode === 8 && index !== 1) {
				getCodeBoxElement(index - 1).value = "";
				getCodeBoxElement(index - 1).focus();
			}
		}
	}
	
	function onFocusEvent(index) {
		return () => {
			for (let item = 1; item < index; item++) {
				const currentElement = getCodeBoxElement(item);
				if (!currentElement.value) {
					currentElement.focus();
					break;
				}
			}
		}
	}
	
 	useEffect(() => {
 		document.querySelector('#ac1').addEventListener('keyup', handleKeyUp(1))
 		document.querySelector('#ac2').addEventListener('keyup', handleKeyUp(2))
 		document.querySelector('#ac3').addEventListener('keyup', handleKeyUp(3))
 		document.querySelector('#ac4').addEventListener('keyup', handleKeyUp(4))
 		document.querySelector('#ac5').addEventListener('keyup', handleKeyUp(5))
 		document.querySelector('#ac6').addEventListener('keyup', handleKeyUp(6))
 	}, [])

 	var getJSON = function(url, callback) {
		var xhr = new XMLHttpRequest();
		xhr.open('GET', url, true);
		xhr.responseType = 'json';
		xhr.onload = function() {
			var status = xhr.status;
			if (status === 200) {
				callback(null, xhr.response);
			} else {
				callback(status, xhr.response);
			}
		};
		xhr.send();
	};

	function showError(error) {
		document.getElementById('loading-animation').style.display = "none";
		document.getElementById('err-' + error).style.height = "35px";
		jQuery('#shakediv').removeClass().addClass('animated shake').one('webkitAnimationEnd oAnimationEnd', function(){
			jQuery(this).removeClass();
		});
		document.getElementById('ac1').value = "";
		document.getElementById('ac2').value = "";
		document.getElementById('ac3').value = "";
		document.getElementById('ac4').value = "";
		document.getElementById('ac5').value = "";
		document.getElementById('ac6').value = "";
		document.getElementById('ac1').focus();
	}

	function beforeOpenDateDexterous(data) {
		var currentDate = new Date();
		
		if (data.poll.openDate == null) {
			document.getElementById('openDateErrorMessage').textContent = "Call Scott on 0476 282 927 to choose dates for surveying.";
			return(true)
		} else {
			var openDate = new Date(data.poll.openDate.date);
			var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
			document.getElementById('openDateErrorMessage').textContent = "Your survey will open on " + openDate.toLocaleDateString("en-AU", options) + '.';
			return(currentDate < openDate)
		}
	}

	function afterCloseDateDexterous(data) {
		var currentDate = new Date();
		
		if (data.poll.closeDate == null) {
			return(false)
		} else {
			var closeDate = new Date(data.poll.closeDate.date);
			var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
			document.getElementById('closeDateErrorMessage').textContent = "Your surveying period ended on " + closeDate.toLocaleDateString("en-AU", options) + '.';
			return(currentDate > closeDate)
		}
	}

	function beforeOpenDate(date) {
		
		var currentDate = new Date();
		
		var openDate = new Date(date);
		var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
		document.getElementById('openDateErrorMessage').textContent = "Your survey will open on " + openDate.toLocaleDateString("en-AU", options) + '.';

		return(currentDate < openDate)
	}

	function afterCloseDate(date) {
	
		var currentDate = new Date();
		
		var closeDate = new Date(date);
		var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
		document.getElementById('closeDateErrorMessage').textContent = "Your surveying period ended on " + closeDate.toLocaleDateString("en-AU", options) + '.';

		return(currentDate > closeDate)
	}

	function fixURL(url) {
		var regex = /[0-9-]+/g;
		var page = url.match(regex)[0];
		return('https://surveypage.org/' + page + '/');
	}

	async function loadSurvey(auth) {

		document.getElementById('err-notNumber').style.height = "0px";
		document.getElementById('err-notYetOpen').style.height = "0px";
		document.getElementById('err-closed').style.height = "0px";
		document.getElementById('err-noSurvey').style.height = "0px";

		var accessCode = document.getElementById('ac1').value + document.getElementById('ac2').value + document.getElementById('ac3').value + document.getElementById('ac4').value + document.getElementById('ac5').value + document.getElementById('ac6').value;
		accessCode = accessCode.toUpperCase();

		// Try fetching 3.0 survey.
		let survey = { error: 'no-user' };
		if (auth.app.currentUser !== null) {
			survey = await auth.app.currentUser.functions.fetchSurvey(accessCode);
		}

		if (window.location.hostname == "resourceboxsurvey.org") {

			// Try to fetch JGI survey.

			if (JGIcodes['lower_pre'].includes(accessCode)) {

				survey = await auth.app.currentUser.functions.fetchSurvey('716250');

			} else if (JGIcodes['upper_pre'].includes(accessCode)) {
				
				survey = await auth.app.currentUser.functions.fetchSurvey('164986');

			} else if (JGIcodes['lower_post'].includes(accessCode)) {

				survey = await auth.app.currentUser.functions.fetchSurvey('365746');

			} else if (JGIcodes['upper_post'].includes(accessCode)) {
				
				survey = await auth.app.currentUser.functions.fetchSurvey('583713');

			} else if (JGIcodes['staff'].includes(accessCode)) {
				
				survey = await auth.app.currentUser.functions.fetchSurvey('815181');

			} else {

				showError('noSurvey');

			}

			if (survey.error === null) {
				setPage(prevPage => {
					let page = {...prevPage};
					page.survey = survey;
					page.id = 'Survey';
					page.accessCode = accessCode;
					return page;
				})
			}

		} else if (survey.error === null) {
		
			if (!/^\d{6}$/.test(accessCode)) {
				showError('notNumber');
				return;
			}

			// 3.0 survey exists.

			if (beforeOpenDate(survey.Survey.openDate)) {

				showError('notYetOpen');
			
			} else if (afterCloseDate(survey.Survey.closeDate)) {

				showError('closed');

			} else {

				setPage(prevPage => {
					let page = {...prevPage};
					page.survey = survey;
					page.id = 'Survey';
					return page;
				})

			}

		} else { // if survey.error == 'no-surveys'

			showError('noSurvey');

		}

	}

	function getLogo() {
		if (theme !== null) {
			if (theme.hasOwnProperty('logo') && theme.logo !== '') {
				return 'static/img/logos-large/' + theme.logo;
			}
		}
		return 'static/img/transparent-placeholder.png';
	}

	function getCopyright() {
		if (theme !== null) {
			if (theme.hasOwnProperty('copyrightSurvey') && theme.copyrightSurvey !== '') {
				let copyright = theme.copyrightSurvey;
				copyright = copyright.replace('{{YEAR}}', (new Date()).getFullYear());
				return { html: copyright };
			}
		}
		return '';
	}

	return html`
		<main>
		<div class="login">
			
			<img src="${getLogo()}" class="logo"/>
			
			<span class="prompt" id="loginPrompt">${window.location.hostname == "resourceboxsurvey.org" ? 'PLEASE ENTER YOUR 6-LETTER ACCESS CODE' : text.loginPrompt}</span><br />
			<div id="shakediv"><input type="text" id="ac1" maxlength="1" onfocus="${onFocusEvent(1)}" autofocus>&ensp;<input type="text" id="ac2" maxlength="1" onfocus="${onFocusEvent(2)}">&ensp;<input type="text" id="ac3" maxlength="1" onfocus="${onFocusEvent(3)}">&ensp;<input type="text" id="ac4" maxlength="1" onfocus="${onFocusEvent(4)}">&ensp;<input type="text" id="ac5" maxlength="1" onfocus="${onFocusEvent(5)}">&ensp;<input type="text" id="ac6" maxlength="1" onfocus="${onFocusEvent(6)}"></div><br />
			
			<div id="loading-animation" class="loader">
				<div class="elements">
					<div class="circle1"></div>
					<div class="circle2"></div>
				</div>
			</div>
			
			<span id="err-notNumber" class="error" style="height:0px"><strong>${text.errorNotNumber.strong}</strong><br />${text.errorNotNumber.plain}</span>
			<span id="err-notYetOpen" class="error" style="height:0px"><strong>${text.errorNotYetOpen.strong}</strong><br /><span id="openDateErrorMessage"></span></span>
			<span id="err-closed" class="error" style="height:0px"><strong>${text.errorClosed.strong}</strong><br /><span id="closeDateErrorMessage"></span></span>
			<span id="err-noSurvey" class="error" style="height:0px"><strong>${text.errorNoSurvey.strong}</strong><br />${text.errorNoSurvey.plain}</span>
			<span id="err-admin" class="error" style="height:0px"><strong>${text.errorContact.strong}</strong><br />${text.errorContact.plain}</span>
				
		</div>
		</main>

		<div class="fineprint">${getCopyright()}</div>

		<div class="translation">${Select(
			[
				{label: '🇦🇺 English', value: 'EN'},
				{label: '🇨🇳 Traditional Chinese', value: 'YUE'},
				{label: '🇨🇳 Simplified Chinese', value: 'CMN'},
			],
			language,
			setLanguage,
			false, {}, false, false
		)}</div>
	`;

});

export default Login;