import {neverland as $, html, useState, useEffect} from 'neverland';

const EndScreen = $(function(page, theme, endScreen) {

	function getLogo() {
		if (theme !== null) {
			if (theme.hasOwnProperty('logo') && theme.logo !== '') {
				return 'static/img/logos-large/' + theme.logo;
			}
		}
		return 'static/img/transparent-placeholder.png';
	}

	function getLogoUrl() {
		if (theme !== null) {
			if (theme.hasOwnProperty('logoUrl') && theme.logoUrl !== '') {
				return theme.logoUrl;
			}
		}
		return '#';
	}

	function getCopyright() {
		if (theme !== null) {
			if (theme.hasOwnProperty('copyrightSurvey') && theme.copyrightSurvey !== '') {
				let copyright = theme.copyrightSurvey;
				copyright = copyright.replace('{{YEAR}}', (new Date()).getFullYear());
				return { html: copyright };
			}
		}
		return '';
	}

	return html`
		<main>
		<div class="endscreen">

			${endScreen ? html`<h1>${endScreen.title}</h1>` : ''}
			${endScreen ? html`<h2>${endScreen.subtitle}</h2>` : ''}

			${endScreen ? { html: `${marked.parse(endScreen.content)}` } : ''}
			
			<a href="${endScreen ? endScreen.website : getLogoUrl()}"><img src="${getLogo()}" class="logo"></a>

		</div>
		</main>

		<div class="fineprint">${getCopyright()}</div>
	`;

});

export default EndScreen;