
import textNode from './node_text.js';
import numberNode from './node_number.js';
import chooseNode from './node_choose.js';
import dropdownNode from './node_dropdown.js';
import paragraphNode from './node_paragraph.js';

function randomString() {
	return Math.random().toString().substr(2, 10);
}

function buildForm(spec) {

	// Define recursive function to construct definition from nested Item Sets
	// and items, including any conditional branches / logic.

	let itemIndices = {};
	function getIdx(item) {
		if (Object.keys(itemIndices).includes(item.id)) {
			itemIndices[item.id] = itemIndices[item.id] + 1;
		} else {
			itemIndices[item.id] = 0;
		}
		return itemIndices[item.id];
	}

	function makeNode(item) {
		let idx = getIdx(item);
		switch (item.type) {
			case "choose-1":
			case "choose-n":
				return chooseNode(item, idx);
				break;
			case "dropdown-1":
				return dropdownNode(item, idx);
				break;
			case "text":
			case "text-area":
				return textNode(item, idx);
				break;
			case "number":
				return numberNode(item, idx);
				break;
			case "paragraph":
				return paragraphNode(item, idx);
				break;
			default:
				return { id: randomString() };
				break;
		}
	}

	function makeConditions(logic) {

		let conditions = [];

		for (let c of logic.conditions) {

			let item = spec.Items.filter(d => d.id == c.item)[0];

			switch (item.type) {
				case "choose-1":
					conditions.push({
						id: randomString(),
						block: {
							choice: `${item.id}-${itemIndices[item.id]}-choice-${c.value}`,
							type: "tripetto-block-multiple-choice",
							version: "4.1.0",
							node: `${item.id}-${itemIndices[item.id]}-node`,
							slot: `${item.id}${itemIndices[item.id]}`.padEnd(64, '0')
						}
					});
					break;
				case "dropdown-1":
					conditions.push({
						id: randomString(),
						block: {
							option: `${item.id}-${itemIndices[item.id]}-choice-${c.value}`,
							type: "tripetto-block-dropdown",
							version: "5.0.0",
							node: `${item.id}-${itemIndices[item.id]}-node`,
							slot: `${item.id}${itemIndices[item.id]}`.padEnd(64, '0')
						}
					});
					break;
				default:
					break;
			}

		}


		return conditions;
	}

	let maxRecursiveCalls = 200,
		nRecursiveCalls = 0;

	function makeCluster({
			item = null,
			elements = null,
			logic = {conditions: []},
			required = null
		}) {

		nRecursiveCalls++;

		let hasItem = (item !== null),
			hasElements = (elements !== null),
			hasLogic = (logic.conditions !== undefined ? logic.conditions.length > 0 : false);

		let cluster = {
			"id": randomString()
		};

		if (hasItem & !hasLogic) {
			
			// Always use Item required status if available, Item Set required status only used as backup.
			item.required = item.hasOwnProperty('required') ? item.required : (required == true);
			cluster.nodes = [makeNode(item)];

		} else if (hasItem & hasLogic) {

			// Always use Item required status if available, Item Set required status only used as backup.
			item.required = item.hasOwnProperty('required') ? item.required : (required == true);
			cluster.nodes = [{ id: randomString() }];
			cluster.branches = [{
				id: randomString(),
				clusters: [makeCluster({ item: item })],
				conditions: makeConditions(logic),
				culling: logic.conditionAggregator
			}]

		} else if (hasElements & !hasLogic) {

			cluster.nodes = [{ id: randomString() }];
			cluster.branches = [{
				id: randomString(),
				clusters: makeClusters(elements)
			}]

		} else if (hasElements & hasLogic) {
		
			cluster.nodes = [{ id: randomString() }];
			cluster.branches = [{
				id: randomString(),
				clusters: makeClusters(elements),
				conditions: makeConditions(logic),
				culling: logic.conditionAggregator
			}]

		}

		return cluster;
	}

	function makeClusters(seq) {

		nRecursiveCalls++;

		let clusters = [];

		for (let el of seq) {

			// Handle differently if an Item, or a link to an Item or ItemSet.
			if (el.isItem) {

				clusters.push(makeCluster({ item: el }));

			} else if (el.collection == "Items") {

				let logic = {
					conditionAggregator: el.conditionAggregator,
					conditions: el.conditions
				};

				// Push Item details to items.
				let matchingItems = spec.Items.filter(d => d.id == el.element);
				if (matchingItems.length > 0) {
					clusters.push(makeCluster({
						item: matchingItems[0],
						logic: logic,
						required: el.required
					}));
				}

			} else if (el.collection == "ItemSets") {

				let logic = {
					conditionAggregator: el.conditionAggregator,
					conditions: el.conditions
				};

				// Extract elements in ItemSet and recursively call makeClusters on it.
				let matchingItemSets = spec.ItemSets.filter(d => d.id == el.element);
				if (matchingItemSets.length > 0 && nRecursiveCalls < maxRecursiveCalls) {
					
					clusters.push(makeCluster({
						elements: matchingItemSets[0].elements,
						logic: logic
					}));
					
				} else if (nRecursiveCalls >= maxRecursiveCalls) {
					console.log("Maximum recursive calls exceeded.")
				}
			}
		}

		return clusters;
	}

	// Create top-level sequence of clusters.
	// (to handle both individual Items and Item Sets)

	let seq = spec.ItemSet.elements;

	// Construct definition.

	let def = {
		clusters: makeClusters(seq),
		builder: {
			name: "tripetto",
			version: "4.1.0"
		},
		epilogue: {
			title: "Your voice matters!",
			description: "Thank you for completing the survey."
		}
	};

	if (spec.hasOwnProperty('Survey')) {
		let redirectUrl = `${window.location.origin}/?p=EndScreen&s=${spec.Survey.endScreen}`;
		def.epilogue.redirectUrl = redirectUrl;
	}

	return def;
}

export default buildForm;