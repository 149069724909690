
function dropdownNode(item, idx) {
	
	let node = {
			id: `${item.id}-${idx}-node`,
			name: item.prompt,
			nameVisible: true
		},
		slots = [{
			id: `${item.id}${idx}`.padEnd(64, '0'),
			type: "string",
			kind: "static",
			reference: "option",
			label: "Selected option",
			required: item.required
		}],
		block = {
			type: "tripetto-block-dropdown",
			version: "3.0.6",
			options: []
		};

	if (item.placeholder !== null && item.placeholder !== '') {
		node.placeholder = item.placeholder;
	}

	if (item.caption !== null && item.caption !== '') {
		block.caption = item.caption;
	}

	if (item.description !== null && item.description !== '') {
		node.description = item.description;
	}

	if (item.options !== undefined && item.options.length > 0) {
	
			let choices = item.options
				.map((c, k) => {
					let opt = {...c};
					if (opt.value === '') {
						opt.value = c.label;
					} else {
						// c.value = Number(c.value);
					}

					return {
						id: `${item.id}-${idx}-choice-${opt.value}`,
						idx: k,
						name: opt.label,
						value: opt.value
					}
				});
			
			for (let c of choices) {
				block.options.push(c);
			}

	}

	return {
		...node,
		slots,
		block
	};
}

export default dropdownNode;