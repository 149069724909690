
function paragraphNode(item, idx) {
	
	let node = {
			id: `${item.id}-${idx}-node`,
			name: item.prompt,
			nameVisible: true
		},
		block = {
			type: "tripetto-block-paragraph",
			version: "2.0.5"
		};

	if (item.caption !== null && item.caption !== '') {
		block.caption = item.caption;
	}

	if (item.description !== null && item.description !== '') {
		node.description = item.description;
	}

	if (item.explanation !== null && item.explanation !== '') {
		node.explanation = item.explanation;
	}

	if (item.imageURL !== null && item.imageURL !== '') {
		block.imageURL = item.imageURL;
		block.imageAboveText = true;
	}

	return {
		...node,
		block
	};
}

export default paragraphNode;