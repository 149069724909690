import {neverland as $, render, html, useState} from 'neverland';

import Login from './Login.js';
import Survey from './Survey.js';
import EndScreen from './EndScreen.js';

const Router = $(function(href, auth, theme, endScreen, page, setPage) {

	// console.log(page.id);

	switch (page.id) {
		case 'Survey':
			document.title = 'Survey';
			return html`
				${Survey(auth, page, setPage)}
			`;
			break;
		case 'EndScreen':
			document.title = 'Thank You';
			return html`
				${EndScreen(page, theme, endScreen)}
			`;
			break;
		case `Login`:
		default:
			document.title = 'Survey Login';
			return html`
				${Login(auth, setPage, theme)}
			`;
			break;
	}

});

export default Router;